<template>
    <BaseContainer>
        <span slot="principal">
            <v-content>
                <v-card elevation="0" class="mb-12">
                    <v-card-title class="justify-center">
                        <v-switch
                            dense
                            inset
                            true-value="ativo"
                            false-value="inativo"
                            hide-details
                            class="pb-2"
                            v-model="form.status"
                            :label="form.status == 'ativo' ? 'Ativo' : 'Inativo'"
                        />
                        <v-spacer />
                        {{ form.id ? 'Edição' : 'Cadastro' }} de Cupom
                        <v-spacer />
                    </v-card-title>
                    <v-card-text align="center" justify="center">
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Nome de Identificação"
                                    v-model="form.descricao"
                                    ref="descricao"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Código do Cupom"
                                    v-model="form.codigo"
                                    v-mask="['XXXXXXXXXXXXXXXXXXXX']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Desconto de Valor"
                                    v-model="form.valor"
                                    placeholder="0.00"
                                    prefix="R$"
                                    v-mask="['#.##','##.##','###.##','####.##','#####.##']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Desconto Percentual"
                                    v-model="form.percentual"
                                    placeholder="0.00"
                                    prefix="%"
                                    v-mask="['#.##', '##.##', '###.##', '####.##', '#####.##']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select
                                    :items="taxaOptions"
                                    v-model="form.frete_gratis"
                                    outlined
                                    dense
                                    label="Taxa de Entrega Grátis?"
                                    hide-details
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Quantidade de Cupons Disponiveis"
                                    v-model="form.quantidade"
                                    type="number"
                                    v-mask="['#','##','###','####','#####']"
                                    outlined
                                    dense
                                    hide-details
                                />
                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Data de Validade Inicio"
                                    type="date"
                                    v-model="form.validade_inicio"
                                    placeholder="DD/MM/YYYY"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field
                                    label="Data de Validade Fim"
                                    type="date"
                                    v-model="form.validade_fim"
                                    placeholder="DD/MM/YYYY"
                                    hide-details
                                    outlined
                                    dense
                                />
                            </v-col>
                        </v-row>

                        <h3 class="mt-8">Disponibilidade</h3>

                        <v-radio-group
                            v-model="form.disponibilidade.sempre_disponivel"
                            row
                            hide-details
                            style="margin-top: 5px;"
                        >
                            <v-radio
                                label="Sempre disponível"
                                :value="true"
                            />
                            <v-radio
                                label="Dias e horários específicos"
                                :value="false"
                            />
                        </v-radio-group>

                        <div v-if="!form.disponibilidade.sempre_disponivel" class="mt-4">
                            <v-row align="center" v-for="d in form.disponibilidade.programacao" :key="d.diaSemana">
                                <v-switch
                                    dense
                                    inset
                                    hide-details
                                    class="mr-2"
                                    style="width: 95px;"
                                    v-model="d.aberto"
                                    :label="d.diaSemana.substr(0, 3)"
                                />
                                <v-text-field
                                    v-model="d.horario"
                                    hide-details
                                    style="max-width: 105px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                />
                                <v-btn
                                    icon
                                    small
                                    color="primary"
                                    v-if="!d.segundoHorarioAtivo"
                                    @click="toogleSegundoHorario(d.diaSemana)"
                                >
                                    <v-icon>mdi-plus</v-icon>
                                </v-btn>
                                <v-text-field
                                    v-else
                                    v-model="d.segundoHorario"
                                    hide-details
                                    style="max-width: 130px; border: 1px solid #ddd; border-radius: 5px; padding-left: 5px;"
                                    v-mask="['##:## - ##:##']"
                                    placeholder="00:00 - 00:00"
                                    :disabled="!d.aberto"
                                >
                                    <template v-slot:append-outer>
                                        <v-btn icon x-small @click="toogleSegundoHorario(d.diaSemana)">
                                            <v-icon color="red">mdi-delete</v-icon>
                                        </v-btn>
                                    </template>
                                </v-text-field>
                            </v-row>
                        </div>

                        <div class="mb-12"/>
                    </v-card-text>
                </v-card>
                <v-footer fixed class="pa-4">
                    <v-btn link large text color="warning" to="/cupons">Cancelar</v-btn>
                    <v-spacer />
                    <v-btn color="success" large @click="salvar" :loading="loading">
                        <v-icon>mdi-content-save</v-icon>&nbsp;Salvar
                    </v-btn>
                </v-footer>
            </v-content>
        </span>
    </BaseContainer>
</template>

<script>
import BaseContainer from '@/components/BaseContainer';

export default {
    name: 'Cupom',

    components: { BaseContainer },

    data: () => ({
        loading: false,
        form: {
            descricao: '',
            codigo: '',
            valor: '',
            percentual: '',
            validade: '',
            quantidade: 100,
            frete_gratis: false,
            ativo: true,
            status: 'ativo',
            disponibilidade: {
                sempre_disponivel: true,
                programacao: [
                    {isoWeekday: 1, diaSemana: 'SEGUNDA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 2, diaSemana: 'TERCA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 3, diaSemana: 'QUARTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 4, diaSemana: 'QUINTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 5, diaSemana: 'SEXTA', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 6, diaSemana: 'SÁBADO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                    {isoWeekday: 7, diaSemana: 'DOMINGO', horario: '', segundoHorario: '', segundoHorarioAtivo: false, aberto: false},
                ],
            },
        },
        taxaOptions: [
            {
                value: true,
                text: 'Sim',
            },
            {
                value: false,
                text: 'Não',
            },
        ],
    }),

    mounted() {
        let dados = this.$store.getters.getData;

        if (dados?.id) {
            this.form = dados;
            this.$store.commit('setData', {});
        }
    },

    methods: {
        salvar() {
            const id = this.form.id;

            if (id) {
                this.loading = true;
                this.$http.put(`cupons/${id}`, this.form).then(resp => {
                    if (resp.data.type === 'warning') {
                        this.notify(resp.data.msg, 'warning');
                        return;
                    }
                    this.$router.push('/cupons');
                    this.notify('Atualizado com sucesso!');
                }).catch(() => {
                    this.notify('Verifique sua conexão com a internet', 'warning');
                }).finally(() => (this.loading = false));
                return;
            }

            this.loading = true;
            this.$http.post('cupons', this.form).then(resp => {
                if (resp.data.type === 'warning') {
                    this.notify(resp.data.msg, 'warning');
                    return;
                }
                this.$router.push('/cupons');
                this.notify('Salvo com sucesso!');
            }).catch(() => {
                this.notify('Verifique sua conexão com a internet', 'warning');
            }).finally(() => (this.loading = false));
        },

        toogleSegundoHorario(diaSemana) {
            const programacao = this.form.disponibilidade.programacao.find(f => f.diaSemana == diaSemana);
            programacao.segundoHorarioAtivo = !programacao.segundoHorarioAtivo;
            this.$forceUpdate();
        },
    },
}
</script>

<style scoped>
.v-card {
    padding: 0 15px 0 15px;
}
</style>
